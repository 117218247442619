import * as React from "react"
import { Heading, Flex } from "theme-ui"

import {
  withPrismicUnpublishedPreview,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews"
import ArticlePageTemplateWithQuery from "../templates/ArticlePageTemplateWithQuery"
import CustomPageTemplateWithQuery from "../templates/CustomPageTemplateWithQuery"

import linkResolver from "../utils/linkResolver"
import { Layout } from "../components/Layout"
import { ResponsiveImage } from "../components/ResponsiveImage"
import { fallbackImageLarge } from "../hooks/fallbackImageLarge"
import { Link } from "../components/Link"

const NotFoundPage = () => {
  const fallbackImage = fallbackImageLarge()
  return (
    <Layout>
      <ResponsiveImage
        image={fallbackImage}
        isBackgroundImage={true}
        sx={{
          backgroundPosition: "top",
        }}
      >
        <Flex
          sx={{
            height: "1000px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Heading
            variant="headingSandYellow"
            sx={{
              textAlign: "center",
              background: t =>
                `radial-gradient(closest-side, ${t.colors.darkSoilBrown}, transparent)`,
              px: ["5%", "200px", "200px"],
              py: "200px",
            }}
          >
            Oops...
            <br />
            {"Looks like you're lost in the deep end of the forest!"}
            <br /> Would you like to return <Link to="/">home</Link>?
          </Heading>
        </Flex>
      </ResponsiveImage>
    </Layout>
  )
}

// e.g., If an unpublished document is previewed, these templates will be rendered.
export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: componentResolverFromMap({
      article: ArticlePageTemplateWithQuery,
      custom_page: CustomPageTemplateWithQuery,
    }),
  },
])
