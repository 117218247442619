/* eslint-disable react-hooks/rules-of-hooks */
import { graphql, useStaticQuery } from "gatsby"

export const fallbackImageLarge = () => {
  const { prismicFallbackImage } = useStaticQuery(
    graphql`
      query FallbackImageLarge {
        prismicFallbackImage {
          data {
            image {
              alt
              gatsbyImageData(width: 1920, placeholder: BLURRED)
            }
          }
        }
      }
    `
  )
  return prismicFallbackImage.data.image
}
